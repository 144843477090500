<template>
    <div class="sgirot-create"
         :class="{'rtl' : isLanguageRtl()}">

        <div class="toolbar uk-flex uk-flex-between">
            <div class="actions" style="margin-inline-start: auto;">
                <div class="uk-button-group uk-flex-gap-s">
                    <form-button
                            type="secondary"
                    @click="doCancel">{{translate('sgirot.general.cancelButton')}}</form-button>
                    <form-button
                            @click="doSubmit">{{translate('sgirot.general.editButton')}}</form-button>
                    <slot name="actionButtons"></slot>
                </div>
            </div>
        </div>

        <slot name="beforeForm"></slot>
        <base-form
                v-if="finalFormConfig"
                ref="editForm"
                :action="formAction"
                :method="formMethod"
                v-model="item"
                v-bind="formProps"
                :config="finalFormConfig"
                @form:submitSuccess="afterSubmit"
        ></base-form>
        <base-form
                v-else
                ref="editForm"
                :action="formAction"
                :method="formMethod"
                v-model="item"
                v-bind="formProps"
                :formPath="formSource"
                :config="{context: 'edit'}"
                @form:submitSuccess="afterSubmit"
        ></base-form>
        <slot name="afterForm"></slot>
    </div>
</template>

<script>
import asyncOperations  from '@/client/extensions/composition/asyncOperations.js';
import {useStore} from "vuex";
import {computed} from "vue";


export default {
    setup (props, setupContext ) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());

        return {asyncOps, asyncOpsReady, asyncStatus,}
    },
    props: {
        subject: {
            type: String,
            default: 'source',
        },
        formSource: {
            type: String
        },
        itemSource: {
            type: String,
            default : '',
        },
        formConfig : {
            type: [Boolean, Object],
            default: false
        },
        redirect: {
            type: [String, Object, Boolean],
            default: '',
        },
        formProps : {
            type: [Object],
            default: {}
        },
        subjectId : {
            type: [String, Number, Boolean],
            default: false
        }
    },
    data: function () {
        return {
            item : {},
            asyncData: {
                item: this.itemSource
            }
        }
    },
    computed: {
        finalFormConfig () {
            return this.formConfig || false;
        },
        formAction () {
            if (this.formProps && this.formProps.action) {
                return this.formProps.action;
            }

            return this.subject + '/' + this.subjectId;
        },
        formMethod () {
            if (this.formProps && this.formProps.method) {
                return this.formProps.method;
            }

            return 'patch';
        }


    },
    methods: {
        doRedirect() {
            if ( ! this.redirect) {
                this.$router.back();
            }

            this.$router.push(this.redirect);
        },
        doCancel () {
            this.doRedirect();
        },
        doSubmit () {
            this.$refs.editForm.submit();
        },
        afterSubmit () {
            this.$s.ui.notification(this.translate('sgirot.general.actionSuccessful'), 'success');
            this.doRedirect();
        },
    },


}
</script>

<style scoped lang="scss">


</style>
