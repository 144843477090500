<template>
  <div class="mister">
      <div class="uk-card uk-card-default uk-card-body ">
          <h1 class="uk-card-title">
              <slot name="pageTitle">{{ translate('sgirot.source.editTitle') }}</slot>
          </h1>
          <div class="page-content">
              <crud-edit
                      :formProps="{fieldWrapperClass : 'uk-flex uk-flex-stretch uk-flex-wrap uk-gap-m flex-break-large-start'}"
                      :formConfig="formConfig"
                      :itemSource="'source/'+itemId"
                      :subjectId="itemId"
                      subject="source"
                      :redirect="{name: 'source'}"
              ></crud-edit>
          </div>
      </div>

  </div>
</template>

<script>

import CrudEdit from '@/client/applications/sgirot/components/crud/Edit.vue';
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";

import userFormFields from '@/client/applications/sgirot/forms/simpleUser.js';


export default {

    setup () {
        return  useMeta({
            title: useI18n().t('sgirot.source.editTitle')
        }).meta;
    },
    props: {
        itemId : {
            default: null
        }
    },
    middleware: {
        sgirotUser: {
            userTypes: [1]
        }
    },
    data () {
        let formFields = {
            ...userFormFields,
            name : {
                name          : 'name',
                type          : 'text',
                label         : 'sgirot.source.fields.name',
                placeholder   : 'sgirot.source.fields.namePlaceHolder',
                class         : '',
                id            : '',
                isPrimaryField: true,
                validation    : {
                    'required': {},
                },
                group         : 'group2',
            }
        };
        formFields.email.validation.liveServerSide = {
            message : 'sgirot.user.emailInUseError',
            params:  {
                'url' : 'source/email',
                'data': {
                    sourceId : this.itemId
                }
            },
        }

        formFields.password.validation = {
            minLength: {
                params: {
                    min: 6
                }
            }
        }


        return {
            entityType: 'source',
            formConfig: {
                fields: formFields,
                groups: {
                    group1: {
                        component: 'FormFieldSet',
                        props    : {
                            title: 'sgirot.source.fields.userFieldsetTitle',
                            icon : 'user',
                            class: 'uk-width-xlarge uk-margin-top',

                        }
                    },
                    group2: {
                        component: 'FormFieldSet',
                        props    : {
                            title: 'sgirot.source.fields.sourceFieldsetTitle',
                            icon : 'info',
                            class: 'uk-width-xlarge uk-margin-top'
                        }
                    }
                },
                buttons: {},
                context: 'edit',
            }
        }
    },
    components: {CrudEdit},
    layout: 'dashboard',
    methods: {

    }
}
</script>
